import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="topub-privacy">
        <h1>Datenschutzrichtlinie</h1>
        <h2 className="topub-privacy__header">
          Datenschutzrichtlinie von topubspotzone.com
        </h2>
        <p className="t-17">
          Bei topubspotzone.com, zugänglich über topubspotzone.com, ist die
          Privatsphäre unserer Besucher eine unserer Hauptprioritäten. Dieses
          Datenschutzrichtliniendokument enthält Arten von Informationen, die
          von topubspotzone.com gesammelt und aufgezeichnet werden, und wie
          wir sie verwenden.
          <br></br>Wenn Sie weitere Fragen haben oder weitere Informationen zu
          unserer Datenschutzrichtlinie benötigen, zögern Sie nicht, uns zu
          kontaktieren.
        </p>
        <h2 className="topub-privacy__header">Protokolldateien</h2>
        <p className="t-17">
          topubspotzone.com folgt einem Standardverfahren für die Verwendung von
          Protokolldateien. Diese Dateien zeichnen Besucher auf, wenn sie
          Websites besuchen. Alle Hosting-Unternehmen tun dies, ebenso wie
          einige Hosting-Service-Bewertungen. Zu den durch Protokolldateien
          gesammelten Informationen gehören Internet Protocol (IP)-Adressen,
          Browsertyp, Internet Service Provider (ISP), Datums- und Zeitstempel,
          Verweis-/Ausstiegsseiten und möglicherweise die Anzahl der Klicks. Sie
          werden nicht mit personenbezogenen Daten verknüpft. Der Zweck der
          Informationen besteht darin, Trends zu analysieren, die Website zu
          verwalten, die Bewegungen der Benutzer auf der Website zu verfolgen
          und demografische Informationen zu sammeln. Unsere
          Datenschutzerklärung wurde mit Hilfe des Privacy Policy Generators
          erstellt.
        </p>
        <h2 className="topub-privacy__header">Unsere Werbepartner</h2>
        <p className="t-17">
          Einige Werbetreibende auf unserer Website verwenden möglicherweise
          Cookies und Web-Beacons. Nachfolgend finden Sie unsere Werbepartner.
          Jeder unserer Werbepartner hat seine eigenen Datenschutzbestimmungen
          für seine Richtlinien bezüglich Benutzerdaten. Um den Zugriff zu
          erleichtern, finden Sie unten Links zu deren Datenschutzrichtlinien.
        </p>
        <h2 className="topub-privacy__header">Datenschutzrichtlinie</h2>
        <p className="t-17">
          In dieser Liste finden Sie die Datenschutzrichtlinien für jeden
          Werbepartner von topubspotzone.com.
          <br></br>Ad-Server oder Ad-Netzwerke von Drittanbietern verwenden
          Technologien wie Cookies, JavaScript oder Web Beacons, die in ihren
          jeweiligen Anzeigen verwendet werden, und Links, die auf
          topubspotzone.com erscheinen und direkt an den Browser der
          Benutzer gesendet werden. In diesem Fall erhalten sie automatisch Ihre
          IP-Adresse. Diese Technologien werden verwendet, um die Wirksamkeit
          ihrer Werbekampagnen zu messen und/oder um die Werbeinhalte zu
          personalisieren, die Sie auf den von Ihnen besuchten Websites sehen.
          <br></br>Bitte beachten Sie, dass topubspotzone.com keinen Zugriff auf
          oder keine Kontrolle über diese von Drittanbietern verwendeten Cookies
          hat.
        </p>
        <h2 className="topub-privacy__header">Datenschutzrichtlinie Dritter</h2>
        <p className="t-17">
          Die Datenschutzbestimmungen von topubspotzone.com gelten nicht für
          andere Werbetreibende oder Websites. Aus diesem Grund empfehlen wir
          Ihnen, für detailliertere Informationen die Datenschutzrichtlinien
          dieser Drittanbieter-Adserver zu konsultieren. Es kann seine Praktiken
          und Anweisungen dazu enthalten, wie man sich von bestimmten
          Wahlmöglichkeiten abmelden kann.
          <br></br>Sie können Cookies über Ihre individuellen Browseroptionen
          deaktivieren. Ausführlichere Informationen zur Cookie-Verwaltung mit
          bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der
          Browser. Was sind Cookies?
        </p>
        <h2 className="topub-privacy__header">Informationen für Kinder</h2>
        <p className="t-17">
          Ein weiterer Schwerpunkt unserer Arbeit ist der Schutz von Kindern bei
          der Nutzung des Internets. Wir ermutigen Eltern und
          Erziehungsberechtigte, ihre Online-Aktivitäten zu beobachten, daran
          teilzunehmen und/oder sie zu überwachen und anzuleiten.
          <br></br>topubspotzone.com sammelt wissentlich keine
          personenbezogenen Daten von Kindern unter 13 Jahren. Wenn Sie glauben,
          dass Ihr Kind diese Art von Informationen auf unserer Website
          bereitgestellt hat, empfehlen wir Ihnen dringend, sich umgehend mit
          uns in Verbindung zu setzen, und wir werden unser Bestes tun, um
          solche Informationen umgehend aus unseren Aufzeichnungen zu entfernen.
        </p>
        <h2 className="topub-privacy__header">
          Nur-Online-Datenschutzrichtlinie
        </h2>
        <p className="t-17">
          Diese Datenschutzrichtlinie gilt nur für unsere Online-Aktivitäten und
          gilt für Besucher unserer Website in Bezug auf die Informationen, die
          sie auf topubspotzone.com geteilt und/oder gesammelt haben. Diese
          Richtlinie gilt nicht für Informationen, die offline oder über andere
          Kanäle als diese Website gesammelt werden.
        </p>
        <h2 className="topub-privacy__header">Zustimmung</h2>
        <p className="t-17">
          Durch die Nutzung unserer Website stimmen Sie unserer
          Datenschutzrichtlinie und den Allgemeinen Geschäftsbedingungen zu.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
