import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import inst from "../../images/instagram.svg";
import tel from "../../images/telegram.svg";
import pin from "../../images/pin.svg";
import phone from "../../images/phone.svg";
import logo from "../../images/logo-footer.svg";

const Footer = () => {
  return (
    <footer className="topub-footer">
      <div className="topub-footer__left">
        <img
          src={logo}
          title="logo"
          alt="logo"
          width="304"
          height="25"
          className="topub-footer__logo"
        />
        <p className="t-17">Copyright © topubspotzone.com</p>
        <div className="topub-footer__flex-container">
          <p className="t-17 topub-footer__flex">
            <img src={pin} title="pin" alt="pin" />
            Werrestraße 69, 32049 Herford, Deutschland
          </p>
          <p className="t-17 topub-footer__flex">
            <img src={phone} title="pin" alt="pin" />
            +49522129590
          </p>
        </div>
      </div>
      <div className="topub-footer__right">
        <div className="topub-footer__links">
          <Link to="/" className="topub-footer__link">
            Startseite
          </Link>
          <Link to="/football" className="topub-footer__link">
            Fußball
          </Link>
          <Link to="/hockey" className="topub-footer__link">
            Eishockey
          </Link>
          <Link to="/basketball" className="topub-footer__link">
            Basketball
          </Link>
          <Link to="/tennis" className="topub-footer__link">
            Tennis
          </Link>
          <Link to="/figure-skating" className="topub-footer__link">
            Eiskunstlauf
          </Link>
        </div>
        <p className="t-17">
          Sie können sich auch an den Kundendienst wenden:{" "}
          <Link to="mailto:topubspotzone@gmail.com">
            topubspotzone@gmail.com
          </Link>
        </p>
        <div className="topub-footer__bottom-container">
          <div className="topub-footer__privacy-link">
            <Link to="/cookies">Cookie-Richtlinie</Link>
            <Link to="/privacy">Datenschutzrichtlinie</Link>
          </div>
          <div className="topub-footer__social">
            <Link to="https://www.instagram.com/">
              <img
                src={tel}
                alt="telegram"
                title="telegram"
                width="32"
                height="32"
              />
            </Link>
            <Link to="https://www.instagram.com/">
              <img
                src={inst}
                alt="instagram"
                title="instagram"
                width="32"
                height="32"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
