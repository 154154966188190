import { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../images/logo.svg";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(false);
  }

  return (
    <header className="topub-header">
      <img
        src={logo}
        title="logo"
        alt="logo"
        width="215"
        height="18"
        className="topub-header__logo"
      />
      <div className="topub-header__link-container">
        <Link to="/" className="topub-header__link">
          Startseite
        </Link>
        <Link to="/football" className="topub-header__link">
          Fußball
        </Link>
        <Link to="/hockey" className="topub-header__link">
          Eishockey
        </Link>
        <Link to="/basketball" className="topub-header__link">
          Basketball
        </Link>
        <Link to="/tennis" className="topub-header__link">
          Tennis
        </Link>
        <Link to="/figure-skating" className="topub-header__link">
          Eiskunstlauf
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="topub-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="topub-header__link" onClick={handleClick}>
              Startseite
            </Link>
            <Link
              to="/football"
              className="topub-header__link"
              onClick={handleClick}
            >
              Fußball
            </Link>
            <Link
              to="/hockey"
              className="topub-header__link"
              onClick={handleClick}
            >
              Eishockey
            </Link>
            <Link
              to="/basketball"
              className="topub-header__link"
              onClick={handleClick}
            >
              Basketball
            </Link>
            <Link
              to="/tennis"
              className="topub-header__link"
              onClick={handleClick}
            >
              Tennis
            </Link>
            <Link
              to="/figure-skating"
              className="topub-header__link"
              onClick={handleClick}
            >
              Eiskunstlauf
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
