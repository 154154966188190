import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./CardSection.css";
import { Navigation, Pagination } from "swiper/modules";
import Card from "../Card/Card";

const CardSection = ({ cards }) => {
  return (
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1.3}
        breakpoints={{
          500: {
            slidesPerView: 2
          },
          749: {
            slidesPerView: 3
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 32,
          }
        }}
        navigation
        pagination={{ clickable: true }}
        className="topub__swiper"
      >
        {cards.map((i, index) => (
          <SwiperSlide className="topub__slide" key={index}>
            <Card data={i} />
          </SwiperSlide>
        ))}
      </Swiper>
  );
};

export default CardSection;
