import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="topub-popup-cookie">
        <form className="topub-popup__form topub-popup__cookie-form">
          <p className="t-23 topub-popup__header">Über Cookies</p>
          <p className="t-17">
            Wir verwenden Cookies, um Ihnen das beste Erlebnis auf unserer
            Website zu bieten.
          </p>
          <div className="topub-popup__btn-container">
            <p
              className="topub-popup__btn-cookie topub-popup__btn-agree t-17"
              onClick={addPopupCookie}
            >
              Bestätigen
            </p>
            <a href="/cookies" className="topub-popup__btn-cookie t-17">
              Weitere Details
            </a>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
