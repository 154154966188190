import React from "react";
import "./Main.css";
import CardSection from "../CardSection/CardSection";
import Events from "../Events/Events";
import Calendar from "../Calendar/Calendar";


const Main = ({newData}) => {
 
  const popular = newData.filter((i) => i.hasOwnProperty("popular"));

  return (
    <main className="topub-main">
      {/* <MainPopup/> */}
      <section className="topub-main__cover">
        <h1 className="topub-main__header col-yellow text-center">
          Tickets und Führungen für Sportveranstaltungen
        </h1>
      </section>
      <section className="topub-popular mg-top">
        <h2 className="topub-popular__header text-center">
          Beliebte Veranstaltungen
        </h2>
        <CardSection cards={popular} />
      </section>
      <Events data={newData}/>
      <Calendar/>
    </main>
  );
};

export default Main;
