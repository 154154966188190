import React from "react";
import "./Events.css";
import CardSection from "../CardSection/CardSection";
import { useLocation } from "react-router-dom";

const Events = ({ data }) => {
  const location = useLocation();
  const football = data.filter((i) => i.game === "football");
  const hockey = data.filter((i) => i.game === "hockey");
  const basketball = data.filter((i) => i.game === "basketball");
  const tennis = data.filter((i) => i.game === "tennis");
  const figureSkating = data.filter((i) => i.game === "figureSkating");

  return (
    <section className="topub-events mg-top">
      {location.pathname === "/" ? (
        <h2 className="topub-events__header text-center">Ereignisse</h2>
      ) : (
        <h1 className="topub-events__header text-center">Ereignisse</h1>
      )}
      <div className="topub-events__list">
        {(location.pathname === "/" || location.pathname === "/football") && (
          <div className="topub-events__item">
            <p className="topub-events__section-name t-23 col-yellow">
              Fußball
            </p>
            <CardSection cards={football} />
          </div>
        )}
        {(location.pathname === "/" || location.pathname === "/hockey") && (
          <div className="topub-events__item">
            <p className="topub-events__section-name t-23 col-yellow">
              Eishockey
            </p>
            <CardSection cards={hockey} />
          </div>
        )}
        {(location.pathname === "/" || location.pathname === "/basketball") && (
          <div className="topub-events__item">
            <p className="topub-events__section-name t-23 col-yellow">
              Basketball
            </p>
            <CardSection cards={basketball} />
          </div>
        )}
        {(location.pathname === "/" || location.pathname === "/tennis") && (
          <div className="topub-events__item">
            <p className="topub-events__section-name t-23 col-yellow">Tennis</p>
            <CardSection cards={tennis} />
          </div>
        )}
        {(location.pathname === "/" ||
          location.pathname === "/figure-skating") && (
          <div className="topub-events__item">
            <p className="topub-events__section-name t-23 col-yellow">
              Eiskunstlauf
            </p>
            <CardSection cards={figureSkating} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Events;
