import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de"; // needed for dayClick
import data from "../../data/data";

import "./Calendar.css";
import SmallCard from "../SmallCard/SmallCard";

const Calendar = () => {
  const [isChosen, setIsChosen] = useState([]);
  const transformEvents = (data) =>
    data.map((event) => ({
      start: event.date,
      display: "background",
      backgroundColor: "#923D0C",
    }));

  const handleDateClick = (info) => {
    const selectedDate = info.dateStr; // Get clicked date in "YYYY-MM-DD" format
    const eventsOnDate = data.filter((event) => event.date === selectedDate);
    setIsChosen(eventsOnDate);
  };

  return (
    <section className="topub-calendar">
      <h2 className="visualy-hidden">Calendar</h2>
      <div className="topub-calendar__body">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={deLocale}
          events={transformEvents(data)}
          dateClick={handleDateClick}         
        />
      </div>
      <div className="topub-calendar__events">
        {isChosen.map((i) => (
          <SmallCard data={i} key={i.name} />
        ))}
      </div>
    </section>
  );
};

export default Calendar;
