import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="topub-privacy">
        <h1>Cookie-Richtlinie</h1>
        <h2 className="topub-privacy__header">
          Willkommen auf unserer Website!
        </h2>
        <p className="t-17">
          Um Ihnen das beste Benutzererlebnis zu bieten und das Surfen auf der
          Website so reibungslos wie möglich zu gestalten, verwenden wir
          Cookies. In dieser Richtlinie erklären wir, was Cookies sind, wie wir
          sie verwenden und welche Optionen Sie hinsichtlich unserer Verwendung
          haben.
        </p>
        <h2 className="topub-privacy__header">Was sind Cookies?</h2>
        <p className="t-17">
          Cookies sind kleine Textdateien, die auf Ihrem Gerät (Computer,
          Smartphone, Tablet) gespeichert werden, wenn Sie eine Website
          besuchen. Sie helfen Websites, sich an Ihre Aktionen und Präferenzen
          zu erinnern (z. B. Sprache, Schriftgröße, Benutzername), sodass Sie
          diese nicht jedes Mal erneut eingeben müssen, wenn Sie eine Website
          besuchen oder von einer Seite zur anderen wechseln.
        </p>
        <h2 className="topub-privacy__header">Wie verwenden wir Cookies?</h2>
        <p className="t-23">Wir verwenden Cookies für folgende Zwecke:</p>
        <ul>
          <li className="t-17">
            *Stellen Sie die Funktionalität der Website sicher: Cookies tragen
            dazu bei, dass die Website ordnungsgemäß funktioniert, indem sie es
            Ihnen beispielsweise ermöglichen, sich bei Ihrem Konto anzumelden
            und Ihren Warenkorb zu verwenden.
          </li>
          <li className="t-17">
            *Bessere Benutzererfahrung: Wir verwenden Cookies, um Ihre
            Präferenzen (z. B. Sprache oder Region) zu speichern und Ihre
            Website-Erfahrung personalisierter und komfortabler zu gestalten.
          </li>
          <li className="t-17">
            * Analyse des Website-Verkehrs: Wir verwenden Cookies, um anonyme
            Informationen darüber zu sammeln, wie Benutzer unsere Website
            nutzen. Diese Daten helfen uns, die Website zu verbessern und sie
            für Sie relevanter zu machen.
          </li>
          <li className="t-17">
            * Werbung und Marketing: Wir können Cookies verwenden, um Werbung
            anzuzeigen, von der wir glauben, dass sie für Sie von Interesse ist.
            Wir verwenden Cookies auch, um die Wirksamkeit unserer
            Werbekampagnen zu überwachen.
          </li>
        </ul>
        <h2 className="topub-privacy__header">
          Welche Arten von Cookies verwenden wir?
        </h2>
        <p className="t-17">Wir verwenden verschiedene Arten von Cookies:</p>
        <ul>
          <li className="t-17">
            * Notwendige Cookies: Diese Cookies sind für die ordnungsgemäße
            Funktion der Website unerlässlich. Sie sammeln keine Informationen
            über Sie.
          </li>
          <li className="t-17">
            * Funktionale Cookies: Diese Cookies ermöglichen es der Website,
            sich Ihre Präferenzen (z. B. Sprache oder Region) zu merken, um Ihr
            Website-Erlebnis persönlicher und komfortabler zu gestalten.
          </li>
          <li className="t-17">
            * Analytische Cookies: Diese Cookies sammeln anonyme Informationen
            darüber, wie Benutzer unsere Website nutzen. Diese Daten helfen uns,
            die Website zu verbessern und sie für Sie relevanter zu machen.
          </li>
          <li className="t-17">
            * Marketing-Cookies: Diese Cookies werden verwendet, um Ihnen
            Werbung anzuzeigen, von der wir glauben, dass sie für Sie von
            Interesse sein könnte.
          </li>
        </ul>
        <h2 className="topub-privacy__header">
          Wie kann ich Cookies verwalten?
        </h2>
        <p className="t-23">
          Sie können die auf unserer Website verwendeten Cookies verwalten.
        </p>
        <ul>
          <li className="t-17">
            *Sie können Ihre Browsereinstellungen jederzeit ändern, um Cookies
            zu blockieren.
          </li>
          <li className="t-17">
            *Sie können vorhandene Cookies von Ihrem Gerät löschen.
          </li>
          <li className="t-17">
            *Sie können die besten Werbekampagnen auswählen, die Sie
            interessieren.
          </li>
        </ul>
        <h2 className="topub-privacy__header">Weitere Informationen</h2>
        <p className="t-17">
          Weitere Informationen zu Cookies finden Sie hier.
        </p>
        <h2 className="topub-privacy__header">Kontaktieren Sie uns</h2>
        <p className="t-17">
          Wenn Sie Fragen zu unserer Cookie-Richtlinie haben, kontaktieren Sie
          uns bitte unter topubspotzone@gmail.com.
          <br></br>Wichtig: Wenn Sie sich dafür entscheiden, Cookies zu
          blockieren, funktionieren einige Funktionen unserer Website
          möglicherweise nicht richtig.
        </p>
        <h2 className="topub-privacy__header">
          Vielen Dank, dass Sie unsere Website nutzen!
        </h2>
      </section>
    </main>
  );
};

export default Cookies;
