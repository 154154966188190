import fcNurnberg from "../images/fc-nurnberg.webp";
import fcMagdeburg from "../images/FC-Magdeburg.webp";
import lokomotive from "../images/lokomotive-leipzig.webp";
import eisbaren from "../images/eisbaren-berlin.webp";
import iceTigers from "../images/Nürnberg-Ice-Tigers.webp";
import mhp from "../images/MHP-RIESEN.webp";
import telekom from "../images/Telekom-Baskets-Bonn.webp";
import ratio from "../images/ratiopharm.webp";
import tageskarte from "../images/Tageskarte.webp";
import holiday from "../images/holiday-on-ice.webp";

const data = [
  {
    popular: true,
    topName: "Fußball",
    game: "football",
    img: fcNurnberg,
    name: "FC Nürnberg - Jahn Regensburg",
    price: "€ 31,50",
    weekDay: "Freitag,",
    date: "2024-10-25",
    link: "https://www.eventim.de/event/1-fc-nuernberg-2-bundesliga-saison-20242025-max-morlock-stadion-19255650/",
  },
  {
    game: "football",
    img: fcMagdeburg,
    name: "FC Magdeburg - Hannover 96",
    price: "€ 18,00",
    weekDay: "Sonntag,",
    date: "2024-10-27",
    link: "https://www.eventim.de/event/1-fc-magdeburg-saison-20242025-mdcc-arena-19100375/",
  },
  {
    game: "football",
    img: fcMagdeburg,
    name: "FC Magdeburg - SSV Ulm 1846",
    price: "€ 2,00",
    weekDay: "Samstag,",
    date: "2024-11-09",
    link: "https://www.eventim.de/event/1-fc-magdeburg-saison-20242025-mdcc-arena-19100376/",
  },
  {
    game: "football",
    img: lokomotive,
    name: "FC Lokomotive Leipzig - F.C. Hertha 03 Zehlendorf",
    price: "€ 12,00",
    weekDay: "Freitag,",
    date: "2024-10-25",
    link: "https://www.eventim.de/event/1-fc-lokomotive-leipzig-regionalliga-nordost-20242025-bruno-plache-stadion-19165195/",
  },
  {
    popular: true,
    topName: "Eishockey",
    game: "hockey",
    img: eisbaren,
    name: "Eisbären Berlin - Grizzlys Wolfsburg",
    price: "€ 20,00",
    weekDay: "",
    date: "2024-10-22",
    link: "https://www.eventim.de/event/eisbaeren-berlin-del-saison-20242025-uber-arena-berlin-18924906/",
  },
  {
    game: "hockey",
    img: eisbaren,
    name: "Eisbären Berlin - Düsseldorfer EG",
    price: "€ 19,00",
    weekDay: "Samstag,",
    date: "2024-10-26",
    link: "https://www.eventim.de/event/eisbaeren-berlin-del-saison-20242025-uber-arena-berlin-18924904/",
  },
  {
    game: "hockey",
    img: iceTigers,
    name: "Nürnberg Ice Tigers - Schwenninger Wild Wings",
    price: "€ 19,00",
    weekDay: "Sonntag,",
    date: "2024-10-27",
    link: "https://www.eventim.de/event/nuernberg-ice-tigers-saison-20242025-arena-nuernberger-versicherung-18953194/",
  },
  {
    game: "hockey",
    img: iceTigers,
    name: "Nürnberg Ice Tigers - Straubing Tigers",
    price: "€ 19,00",
    weekDay: "Freitag,",
    date: "2024-11-01",
    link: "https://www.eventim.de/event/nuernberg-ice-tigers-saison-20242025-arena-nuernberger-versicherung-18953195/",
  },
  {
    game: "basketball",
    img: mhp,
    name: "MHP RIESEN Ludwigsburg - JDA Bourgogne Dijon",
    price: "€ 11,00",
    weekDay: "Mittwoch,",
    date: "2024-10-23",
    link: "https://www.eventim.de/event/mhp-riesen-ludwigsburg-saison-20242025-mhparena-ludwigsburg-19214152/",
  },
  {
    game: "basketball",
    img: telekom,
    name: "Telekom Baskets Bonn - MHP RIESEN Ludwigsburg",
    price: "€ 18,00",
    weekDay: "Montag,",
    date: "2024-10-28",
    link: "https://www.eventim.de/event/telekom-baskets-bonn-saison-202425-telekom-dome-19109683/",
  },
  {
    game: "basketball",
    img: ratio,
    name: "ratiopharm ulm - Veolia Towers Hamburg",
    price: "€ 12,40",
    weekDay: "Samstag,",
    date: "2024-11-02",
    link: "https://www.eventim.de/event/ratiopharm-ulm-easycredit-bbl-hauptrunde-202425-ratiopharm-arena-19071876/",
  },
  {
    popular: true,
    topName: "Basketball",
    game: "basketball",
    img: ratio,
    name: "ratiopharm ulm - RASTA Vechta",
    price: "€ 20,00",
    weekDay: "Samstag,",
    date: "2024-10-26",
    link: "https://www.eventim.de/event/ratiopharm-ulm-easycredit-bbl-hauptrunde-202425-ratiopharm-arena-19071873/",
  },
  {
    popular: true,
    topName: "Tennis",
    game: "tennis",
    img: tageskarte,
    name: "Tageskarte - Montag",
    price: "€ 35,50",
    weekDay: "Montag,",
    date: "2025-06-16",
    link: "https://www.eventim.de/event/terra-wortmann-open-2025-owl-arena-18821521/",
  },
  {
    game: "tennis",
    img: tageskarte,
    name: "Tageskarte - Dienstag",
    price: "€ 35,50",
    weekDay: "Dienstag,",
    date: "2025-06-17",
    link: "https://www.eventim.de/event/terra-wortmann-open-2025-owl-arena-18821522/",
  },
  {
    game: "tennis",
    img: tageskarte,
    name: "Tageskarte - Mittwoch",
    price: "€ 44,50",
    weekDay: "Mittwoch,",
    date: "2025-06-18",
    link: "https://www.eventim.de/event/terra-wortmann-open-2025-owl-arena-18821601/",
  },
  {
    game: "tennis",
    img: tageskarte,
    name: "Tageskarte - Donnerstag",
    price: "€ 44,50",
    weekDay: "Mittwoch,",
    date: "2025-06-19",
    link: "https://www.eventim.de/event/terra-wortmann-open-2025-owl-arena-18821600/",
  },
  {
    popular: true,
    topName: "Eiskunstlauf",
    game: "figureSkating",
    img: holiday,
    name: "Holiday on Ice - HORIZONS | Premiere",
    price: "€ 38,40",
    weekDay: "Mittwoch,",
    date: "2024-11-13",
    link: "https://www.eventim.de/event/holiday-on-ice-horizons-grefrath-grefrather-eissport-eventpark-17885835/?affiliate=TUG",
  },
  {
    game: "figureSkating",
    img: holiday,
    name: "Holiday on Ice - HORIZONS",
    price: "€ 38,40",
    weekDay: "Donnerstag,",
    date: "2024-11-14",
    link: "https://www.eventim.de/event/holiday-on-ice-horizons-grefrath-grefrather-eissport-eventpark-17904697/?affiliate=TUG",
  },
  {
    game: "figureSkating",
    img: holiday,
    name: "Holiday on Ice - HORIZONS",
    price: "€ 42,40",
    weekDay: "Freitag,",
    date: "2024-11-15",
    link: "https://www.eventim.de/artist/holiday-on-ice/holiday-on-ice-horizons-grefrath-3506011/?affiliate=TUG",
  },
  {
    game: "figureSkating",
    img: holiday,
    name: "Holiday on Ice - HORIZONS",
    price: "€ 47,40",
    weekDay: "Samstag,",
    date: "2024-11-16",
    link: "https://www.eventim.de/event/holiday-on-ice-horizons-grefrath-grefrather-eissport-eventpark-17904711/?affiliate=TUG",
  },
];

export default data;
