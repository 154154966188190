import React from "react";
import "./Card.css";
import calendar from "../../images/calendar-icon.svg";
import { Link } from "react-router-dom";

const Card = ({ data }) => {
  return (
    <div className="topub-card">
      <p className="topub-card__topname t-17">{data.topName}</p>
      <img
        src={data.img}
        title={data.name}
        alt={data.name}
        width="304"
        height="302"
        className="topub-card__img"
      />
      <div className="topub-card__text-container">
        <p className="t-17 topub-card__name">{data.name}</p>
        <p className="t-23 col-yellow">Tickets ab {data.price}</p>
        <p className="topub-card__date">
          <img src={calendar} title="calendar icon" alt="calendar icon" />
          {data.weekDay} {data.date}
        </p>
        <Link to={data.link} className="topub-card__link" target="_blank">
          Weitere Details
        </Link>
      </div>
      <p></p>
    </div>
  );
};

export default Card;
