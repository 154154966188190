import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Main from "../Main/Main";
import data from "../../data/data";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import Header from "../Header/Header";
import Events from "../Events/Events";

function App() {

  const convertDateFormat = (dateString) => {
    const [year, month, day] = dateString.split("-"); // Split by "."
    return `${day}.${month}.${year}`; // Rearrange to "YYYY-MM-DD"
  };

  const newData = data.map((i) => {
    const newDate = convertDateFormat(i.date);
    return {
      ...i, date: newDate
    }
  })


  return (
    <div className="topub-page">
      <ScrollToTop />
      <Popup />
      <Header />
      <Routes>
        <Route path="/" element={<Main newData={newData}/>} />
        <Route path="/football" element={<Events data={newData}/>} />
        <Route path="/hockey" element={<Events data={newData}/>} />
        <Route path="/basketball" element={<Events data={newData}/>} />
        <Route path="/tennis" element={<Events data={newData}/>} />
        <Route path="/figure-skating" element={<Events data={newData}/>} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
