import React from "react";
import "./SmallCard.css";
import calendar from "../../images/calendar-icon.svg";
import { Link } from "react-router-dom";

const SmallCard = ({ data }) => {
  return (
    <div className="topub-small">
      <p className="t-23">{data.name}</p>
      <div className="topub-small__middle">
        <p className="t-23 col-yellow">Tickets ab {data.price}</p>
        <p className="topub-card__date">
          <img src={calendar} title="calendar icon" alt="calendar icon" />
          {data.weekDay} {data.date}
        </p>
      </div>
      <Link to={data.link} className="topub-small__link" target="_blank">
        Weitere Details
      </Link>
    </div>
  );
};

export default SmallCard;
